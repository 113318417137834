body {
  margin: 0;
  font-family: "Quicksand", sans-serif !important;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .carousel-item-padding {
    padding-right: 12px;
    box-sizing: border-box;
}

.carousel-container {
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
} */
/* body{
  font-size: 0.5rem !important;
} */
 /*:root {
  --main-bg-color: #99d9d9;
  --white: #fff;
  --black: #000;
  --mediumseagreen: #3cb371;
  --green: #008000;
  --red: #ff0000;
 } */

.main-medicine-page {
  /*background-color: var(--main-bg-color);*/
  padding-bottom: 2vh;
}

.service {
  /*background-color: var(--main-bg-color);*/
  height: 100%;
  width: 100%;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

/* Why is there text-align center added to the whole app, whoever did this is stupid */
.App {
  /* text-align: center; */
  /*background-color: var(--white);*/
}
.card {
  transition: ease-in-out 0.6s ;
  align-items: center;
}
.card .cart-body {

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
/* .card:hover{
  transform: scale(1.05);
} */
.card-service:hover{
  border-bottom: 5px solid rgb(104, 22, 104);
}
.card-service{
  height: 40vh !important
}

/*}.home-page{
  background-color: #99D9D9;
}*/


p{

  font-size: calc(0.3vw + 0.5rem) !important;
  margin-bottom: 1px !important;
}
h5{
  font-size: calc(0.9vw + 0.5rem) !important;
}
h2{
  font-size: calc(1vw + 0.5rem) !important;
}
.dropdown-menu .show{
  font-size: 12px;
}

.time-for-appoitment{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
}

.clinic p{
  font-size: calc(0.4vw + 0.5rem) !important;
}
/* .btnonhover:hover{
  background-color: hsla(144, 100%, 36%, 0.78);
} */

.btn-text{
    font-size: calc(0.4vw + 0.5rem) !important;
    margin-bottom: 0.2rem;
}
body {
  height: 100%;
  overflow-x: hidden;
}
.inputResult{
  text-align: center;
  margin-top: 5px;
  width: 40%;
  max-height: 22vh;
  /*background-color: white;
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;*/
  overflow: hidden;
  overflow-y: auto;
  z-index: 2;
  position: absolute;
  border-radius: 5px;
}
.inputResult::-webkit-scrollbar{
  display: none;
}
.inputResultofmodal{
  text-align: center;
  margin-top: 5px;
  width: 80%;
  max-height: 50vh;
  /*background-color: white;
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;*/
  overflow: hidden;
  overflow-y: auto;
  z-index: 2;
  position: absolute;
  border-radius: 5px;
}
.inputResultofmodal::-webkit-scrollbar{
  display: none;
}
.location-search-under{
  display: none !important;
}
/* .search-location-under{
  display: none;
} */
/* .dropdown-location{
  display: block;
}
.search-location{
  display: block;
} */
.medicine-shop{
  width: 12vw !important;
}
.medicine-card-card{
  width: 11vw !important;
}
.drop-dwon-profile {
  width : 4vw;
  height : 4vh;
}
/* .logo-image{
  width:5vw;
  height :5vh;
} */


.dropdown-location-on-top{
  display: none !important;
}

/* .slider-container {
  width: 100%;
  height: 100%;
  position:fixed; 
  top:0px; 
  left:0px;
} */
.react-multi-carousel-item{
  width: 25vw;
}
.btn-text p{
  margin-bottom: 0px;
}
.line-header{
  display: none;
}
.dropdown-location-under {
  display: none;
}
.Header2-search img{
 display: none;
}
.HamburgerMenu{
  width: 50vw;
  position: fixed;
  z-index: 2;
  margin-top: 16vh;
}
.HamburgerMenu span{
  /*color: white;*/
  z-index: 1;
  position: fixed;
  margin-left: 17vw;
}
.HamburgerMenu  h5{
  font-size:calc(1.85vw + 0.5rem) !important;
  z-index: 2;
  position: fixed;
  /*color: white;*/
  margin:2vw ;
}

.HamburgerMenu ul {
   padding-top: 8vh;
    text-decoration: none;
    list-style: none;
    /*background-color: #187267;
    color: white;*/
    position: fixed;
    height: 51%;
    width: 50vw;
    text-align: center;
    padding-left: 2px;
}
.HamburgerMenu ul li{
  margin: 10px;
  text-decoration: none;
}
.HamburgerMenu ul li .li{
  margin: 10px;
  text-decoration: none;
}
.product-card{
  min-width: 12vw;
}
.product-card-card{
  max-width: 12vw;
}
.after-noon-time{
  margin: 0 2rem !important;
}
.doctor-c-l{
  max-width: 65vw;
}
.card-body{
  padding: 10px 0 !important;
}
.product-card-div{
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.product-card-div a{
  text-decoration: none; 
  /*color: black;*/
  width:100%;
}

/*.product-btn:hover{
  background-color: #cae0e0f7 !important;
}*/

.person{
  height: 7%;
  width: 7%;
  /*border: 5px solid #07dbc1;*/
  border-radius: 50%;
}

.deals a{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.deals a{
  text-decoration: none;
}
.deals p{
 font-weight: 600;
}
.doctor .deccription{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.doctor .deccription .doctor-c-d{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
 }
 .profile-full-class-mob{
  display: none !important;
}
 .partner-profile-full-class-mob{
  display: none !important;
}

table th{
  font-size: 13px !important;
  padding: 4px !important;
}

table td{
  font-size: 13px !important;
  padding: 4px !important;
}
table td button{
  font-size: 13px !important;
}

.sub-admin-quicklink{
  font-size: 12px !important;
}

.product-details{ /* Safari */
  position: sticky !important;
  top: 0 !important;
}
.particular-product{
  height: 81vh !important;
}
.ourserviceCarousel{
  height: 53vh;
}
.allSpecialitiesDoctorsCarousel{
  height: 30vh;
}
.allDoctorsCarousel{
  height: 29vh;
}
.allLabsCarousel{
  height: 35vh;
}
.allLabTestCarousel{
  height: 27vh;
}
.allMedicinesShopsCarousel{
  height: 30vh;
}
.allCategoryCarousel{
  height: 35vh;
}
.productCarousel{
  height: 27vh;
}

.allProductOfAMedicineShopCard{
  width: 70% !important;
}
/* .particular-doctor-page{
  height: 100vh !important;
} */
.doctor-profile_img{
  width: 45% !important;
}

.password-toogle-icon{
  position: absolute;
  top: 60px;
  right: 43px;
  cursor: pointer;
}

.password-toogle-icon-signup{
  position: absolute;
  top: 49px;
  right: 43px;
  z-index: 1000;
  cursor: pointer;
}


.dis-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 50vh;
}
.display-flex-d{
    display: flex;
    align-items: center;
    justify-content: center;
}
.min-h-20{
  height: 22vh;
  overflow: hidden;
}
.min-h-30{
  min-height: 30vh;
}
.min-h-50{
  min-height: 50vh;
}

.header-location-1{
    width: 20vw !important;
    font-size: 1em !important ;
    /*color: black !important;
    background-color: white !important;*/
}
.choose-role-div{
  max-width: 85vw;
  border-radius: 5px;
  overflow: 'hidden';
  background: linear-gradient(rgba(250,0,0,-0.5),transparent);
  /*background-color: rgb(41 116 132);*/
  width: '41vw';
  z-index: 100 !important;
}

.complete_profile_divs{
  display: flex; 
  align-items: center;
   justify-content: space-between;
}
.banner-container{
  margin: 0px !important;
}
.list-doctor-suggested{
  width: 45% !important;
}
.listof-doctors{
  width: 100% !important;
}
.listof-doctors-sug-container{
  width: 40% !important;
}

.doctor-card-button{
  width: 50% !important;

}

.addproductdivlist{
  display: flex;
}

.prescription_div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#printable-content{
  display: flex;
  align-items: center;
  justify-content: center;
}


.prescription_pad{
  display: flex;
  min-height: 90vh;
  width: 65vw;
  justify-content: center;
  flex-direction: column;
  margin-top: 5vh;
  /*border : 4px solid #00000042;*/
}


.prescription_head{
    height: 20vh;
    width: 100%;
    /*border-bottom : 4px solid #00000042;*/
    display: flex;
}
.prescription_body{
    width: 100%;
    min-height: 80vh;
    display: flex;
}


.prescription_left{
  width: 40%;
  height: 100%;
  /*border-right : 4px solid #00000042;*/
  min-height: 80vh;
}
.prescription_right{
  width: 60%;
  height: 100%;
  padding: 15px;
  min-height: 80vh;
}
.prescription_head .logo {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
}
.prescription_head .logo img{
  max-height: 80%;
}
.prescription_head .doc_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10%;
  width: 60%;
}
.desease_details{
    height: 100%;
    padding: 1rem;
}
.desease_details .symptoms{
  min-height: 26vh;
}
.desease_details .tests{
  min-height: 26vh;
}
.desease_details .advice{
    min-height: 26vh;

}
.desease_details .dd_header{
  width: 100%;
      text-align: center;
      /*background: mediumseagreen;
      color: white;*/
      padding: 5px;
      font-weight: 800;
      font-size: 16px;
}
.prescription_right .medicine{
  min-height: 66vh;
}
.prescription_right .medicine textarea{
  width: 100%;
  height: 86%;
  border: none;
}
.textarea textarea{
  border: 0px solid transparent;
  width: 100%;
}

.symptoms  ul{
    list-style: square;
    margin: 0;
    padding-left: 10px;
    min-height: 22vh;
}
.symptoms ul li{
  text-align: left;
  display: list-item;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
}
.tests  ul{
    list-style: square;
    margin: 0;
    padding-left: 10px;
    min-height: 22vh;
}
.tests  ul li{
  text-align: left;
}
.advice ul{
    list-style: square;
    margin: 0;
    padding-left: 10px;
    min-height: 22vh;
}
.advice  ul li{
  text-align: left;
}
.med{
  text-align: left;
}
.med .schedual{
  display: flex;
  justify-content: space-between;
}
.med .schedual select{
  border: 0px;
}

.med_name_action{
  display: flex;
  justify-content: space-evenly;
}
.med input{
  border: 0px;
}
.del_action{
  display: flex;
  justify-content: space-between;
}

.print_div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-gaps{
  height: 15vh;
  overflow: scroll;

}



.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  /*border: 1px solid #cccccc;*/
  border-bottom: none;
}

/*.tab:hover {
  background-color: #f0f0f0;
}*/

/*.tab.active {
  background-color: #007bff;  Change this color for the active tab 
  color: var(--white);  Text color for active tab 
}*/

.tab-content {
  padding: 8px;
  /*border: 1px solid #ccc;*/
  border-top: none;
}

.tabcontent {
  display: none;
}

.tabcontent.active {
  display: block;
}

.tablink {
  /*background-color: #f2f2f2;*/
  border: none;
  cursor: pointer;
  padding: 10px 20px;
}

/*.tablink.active {
  background-color: #ddd;
}*/

.doc_table{
  width: 108% !important;
  overflow-x: auto;
}

.cart p{
  margin-bottom: 1rem !important;
  margin-left: -1vw;
}


@media screen and (max-width: 1050px) {
  .banner-container{
    margin: 0px !important;
  }
  .list-doctor-suggested{
    width: 70% !important;
  }
  .container{
    margin: 0px !important;
  }
  .profile-full-class{
    justify-content: center;
  }
  .navbar-brand h5{
    font-size:calc(1.5vw + 0.5rem) !important; 
  }
  .cartPage{
    flex-direction:column;
    justify-content: center;
   }
   .order-detail{
    min-width: 60vw;
   }
   .order{
    min-width: 70vw;
   }

   .order_payment_page{
      flex-direction:column;
      justify-content: center;
   }
   .order-payment{
      min-width: 70vw;
   }
   .order-detail{
      min-width: 60vw;
   }
   p{
    font-size:calc(0.3vw + 0.5rem) !important;
    margin-bottom: 0.2rem !important;
  }
   
  
  .servicess{
    flex-direction: column;
  }
  .card-service{
    max-width: 40vw !important;
    margin-top: 2vh;
  }
  .card-service{
    height: 37vh !important
  }
  .p-clinic{
    flex-direction: column;
  }
  .p-clinic .a{
    width: 1000%;
  }
  
  .p-clinic .doctor-list-clinic{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .clinics-list{
    width: 100vw !important;
  }
  .img-particular-clinic{
    width: 35vw !important;
  }
  /* .madicine-shop{
    max-width: 18vw !important;
  } */
  .medicine-shop{
    width: 20vw !important;
  }
  .medicine-card-card{
    width: 20vw !important;
  }
  .container{
    display: flex;
    justify-content: space-between;
    max-width: 100% !important;
    overflow: hidden;
  }

  .product-card{
    width: 20vw !important;
  }
  .product-card-card{
    max-width: 20vw !important;
  }
  .product-card-card .card-body p{
        margin-top: 0px !important ;
  }
  .particular-doctor-page{
    flex-direction: column;
    align-items: center;
  }
  .particular-doctor{
    width: 90% !important;
    margin: auto !important;
  }
  .doctor{
    width: 100% !important;
    margin-top: 3rem  !important;
    /* height: 103%; */
    padding: 1rem !important;
  }
  .list-of-doctor{
    width: 80% !important;
  }
  .licence-add{
    flex-direction: column;
  }
  .image-upload{
    flex-direction: column;
  }
  .doctor-c-l{
    max-width: 100vw !important;
  }
  .partner-profile-full-class-mob{
    display: block !important;
  }
  .partner-profile-full-class{
    display: none !important;
  }
  
  .allProductOfAMedicineShopCard{
    width: 100% !important;
  }
  
  .navbar-expand-md{
    justify-content: space-between !important;
  }
  .ourserviceCarousel{
    height: 45vh !important;
  }
  .prescription_pad{
    width: 97% !important;
    font-size: 12px !important;
  }
  .prescription_head .logo img {
    max-height: 60%;
  }

  .allDoctorsCarousel{
    height: 22vh !important;
  }

  .allLabTestCarousel{
    height: 20vh !important;
  }

  .allMedicinesShopsCarousel{
    height: 22vh !important;
  }

  .productCarousel{
    height: 20vh;
  }

  .allSpecialitiesDoctorsCarousel{
    height: 22vh !important;
  }
  .cart p{
    margin-bottom: 1rem !important;
    margin-right: 1vw;
  }
}


@media screen and (max-width: 767px) {
  /* .App {
    background-color: lightgreen;
  } */

  .inputResult{
    width: 90%;
  }
  .ourserviceCarousel{
    height: 30vh !important;
  }
  .list-doctor-suggested{
    width: 85% !important;
  }
  .header-location-mobile{
 /*   color: var(--black) !important;
      background-color: var(--white) !important;*/
      width: 30vw !important;
      font-size: 1em !important;
      border: 0px !important;
      padding: 8px !important;
      margin-bottom: 5px;
      border-radius: 5px;
  }
  .sub-admin-quicklink{
    font-size: 0.5rem !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  .sub-admin-quicklink-items{
    margin: 1px  !important;
    padding: 2px !important;
  }
  .admin-quicklink{
      width: 23% !important;
  }
  .admin-content{
      width: 77% !important;
  }
  table th{
    font-size: 0.6rem !important;
  }
  table td{
    font-size: 0.5rem !important;
    padding: 0 !important;
  }
  table td button{
    font-size: 0.5rem !important;
  }
  /*.for-dealer-btn{
    /* width: 12vw !important; 
  } */
  .profile-left-sidebar{
    display: none;
  }
  .profile-right-content{
    width: 100% !important;
    min-height: 31vh !important;
  }
  .profile-full-class-mob{
    display: block !important;
  }
  .profile-full-class{
    display: none !important;
  }
  .navbar-brand .logo-image{
    display: none !important;
  }
  
  .line-header{
    display: block !important;
    padding: 2px !important;
  }
  .line-icon-header{
    width: 30px !important;
  }
  .login-text p{
    font-size:calc(1vw + 0.5rem) !important; 
  }
  
  .navbar-brand h5{
    font-size:calc(2vw + 0.5rem) !important;
    margin-top: 10px; 
  }
  /* .card-img-top{
    height: 10vh  !important;
  } */
  .profile-icon{
    margin: 0px !important;
  }
  .cart p{
    margin-bottom: 1rem !important;
    margin-right: 1vw;
  }
  .drop-dwon-profile {
    width: 5vw;
    height: 5vh;
  }
  .login-img{
    display: none;
  }
 .modal-img{
  display: none;
 }
 
 .searchDoctor{
  min-width: 90vw !important;
 }

 .modal-serach{
  width: 65vw  !important;
 }
 .search-d{
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }
 .medicines{
  min-width: 90vw !important;
 }
 .search-m{
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }
 .lab{
  min-width: 90vw !important;
 }
 .search-l{
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }
 .close-btn{
  margin-left: 85% !important;
 }



 .search-module{
  display: none !important;
}
.socialIcon{
  display: none !important;
}
.book-btn {
  padding: 1px 2px !important;
}
.banner{
display: flex;
flex-direction: column-reverse;
align-items: center;
}
.baner-text{
max-width: 80vw !important;
}
.Heading{
font-size: calc(4vw + 0.5rem) !important;
}
.banner-img{
max-width: 54vw !important;
}
h5{
font-size: calc(1vw + 0.5rem) !important;
}

h2{
font-size: calc(2vw + 0.5rem) !important;
}

h3{
font-size: calc(2vw + 0.5rem) !important;
}

/* .madicine-shop{
max-width: 25vw;
} */

.search-location{
  display: none;
}

.dropdown-location {
  display: none;
}
.dropdown-location-under {
  display: block;
  margin-left: 1vw !important;
}
.left{
  justify-content: end !important;
  width: 10vw !important;
  margin: 0px !important;
}
/* .cartItemNo{
  margin-left: 5px !important ;
} */
/* .dropdown-location-under{
  display: block;
  width: auto !important;
}
.search-location-under{
  display: block;
  width: auto !important;
} */
 .location-search-under{
  display: flex !important;
  margin-right: 1vw !important;
  /* justify-content: center !important; */
 }
 /* .inputResult{
  width: 50% !important;
  margin-top: 40px !important;
 } */
 /* .dropdown-location-under{
  width: 20vw !important;
  margin: 3px;
 } */
 .search-location-under{
  width: 42vw !important;
 }
 .medicine-shop{
  width: 25vw !important;
}
.medicine-card-card{
  width: 25vw !important;
}
/* .dropdown-toggle {
  width: 11vw !important;
} */
.dropdown-location-modal-m {
  margin: 5px;
  min-width: 50vw;
}
.search-location-modal-m{
  
  min-width: 65vw;
}
.search-m-in-modal{
  margin-top: 5px !important;
}
.Header2-search{
  flex-direction: column;
}
.Header2-search svg{
  display: none;
}

.Header2-search img{
  display: block;
  width: 5vw;
  border-radius: 50%;
  /*border: 2px solid var(--white);*/
}
.product-card-card{
  max-width: 30vw !important;
}
.product-card{
  width: 30vw !important;
}

.card-body-product-discount{
  margin-top: 0px !important;
}

.particular-product{
  flex-direction: column;
  height: auto !important;
}
.product-details{
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
}
.productimage img{
  width: 35vw !important;
}.productdetail{
  height: auto !important;
}
.products{
  width: 100% !important;
  /* align-self: center; */
  padding-left: 2vw;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}
.ProductCardForList{
  width: 95vw !important;
}
.ProductCardForList img{
  width: 9vw !important;
  height: auto !important;
}
.ProductCardForList p{
  font-size: calc(1vw + 0.5rem) !important;
}
.filter{
  width: 25vw !important;
}
.filter{
display: none !important;
}
.list-product-results{
  width: 100%;
}
.dropdown-toggle-modal{
  width: 50vw !important;
}
.particular-doctor{
  width: 97% !important;
}
  .doctor{
    margin: auto !important;
    margin-top: 2rem !important;
  }
  .after-noon{
    margin: 1px !important;
  }
  .evening{
    margin:10px 1px !important;
  }
  .doctor-appoiment{
    flex-direction: column;
    align-items: center;
  }
  .doctor-appoiment-doctor-profile{
    width: 97% !important;
    margin: auto !important;
  }
  .doctor-appoiment-from{
    width: 100% !important;
    margin: 1rem 1rem !important;

  }
  .deccription img{
    max-width: 23vw !important;
  }
  .doctor_deccription{
    width: 40vw !important;
  }
  .doctor-profile_img{
      width: 60% !important;
  }
  .card-service{
      height: auto !important;
  }
  
  .person{
    height: 7%;
    width: 10% !important;
  }
  .complete_profile_divs input{
    width: 40vw !important;
  }
  .min-h-20{
    height: 19vh;
  }
  .allSpecialitiesDoctorsCarousel{
    height: 20vh !important;
  }
  .allDoctorsCarousel{
    height: 30vh !important;
  }
  .allLabsCarousel{
    height: 30vh !important;
  }
  .allLabTestCarousel{
    height: 27vh !important;
  }
  .allMedicinesShopsCarousel{
    height: 27vh !important;
  }
  .allCategoryCarousel{
    height: 25vh !important;
  }
  .productCarousel{
    height: 25vh !important;
  }
  .orderbyempinput{
    width: 75% !important;
  }
  .prescription_pad{
    width: 97% !important;
    font-size: 12px !important;

  }

}

@media screen and (max-width: 550px) {
  .list-doc-container{
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0;
    padding: 0;
    
  }
  .doctor-card-button{
    width: 100% !important;
  
  }
  .listof-doctors{
    width: 90% !important;
  }
  .listof-doctors-sug-container{
    width: 90% !important;
  }
  .login-text {
    margin: 8px !important;
  }
  .ourserviceCarousel{
    height: 30vh !important;
  }
  /*.for-dealer-btn{
    /* width: 19vw !important; 
  }  */
  .line-icon-header{
    width: 20px !important;
  }
  .header-left{
    padding: 0px !important;
  }
  /* .card-img-top{
    height: 9vh  !important;
  } */
  .min-h-20{
    height: 18vh;
  }
  .drop-dwon-profile {
    width: 6vw;
    height: 6vh;
  }
  .Header2-search img{
    width: 10vw !important;
  }
  .cartPage{
    flex-direction:column;
    justify-content: center;
   }
   .order-detail{
    min-width: 90vw;
   }
   .order{
    min-width: 90vw;
  }
  .order-payment{
    min-width: 90vw;
 }
 .order-detail{
    min-width: 90vw;
 }
 .servicess-sm{
  flex-direction: column;
 }
 .card-service{
  max-width: 70vw !important;
}
.fotter-copy-right{
  flex-direction: column;
  width: auto;
  align-items: flex-start;
}

 .doctor .deccription{
  flex-direction: column;
  align-items: center;
 }
 .doctor .deccription img{
  width: 27vw !important;
 }
 .doctor .deccription .doctor-c-d{
  width: auto !important;
 }
 
 
 .img-particular-clinic{
  width: 43vw !important;
}
/* .madicine-shop{
  max-width: 44vw !important;
} */

.left{
  justify-content: end !important;
  width: 25vw !important;
}
.medicine-shop{
  width: 38vw !important;
}
.medicine-card-card{
  width: 38vw !important;
}
.profile-icon{
  margin-right: 0rem!important;
}
.book-btn{
  padding: 2vw !important;
}
.Header-2{
  justify-content: inherit !important;
}
.product-card-card{
  max-width: 40vw !important;
}
.product-card{
  width: 40vw !important;
}
.filter{
  display: none !important;
}
.list-product-results{
  width: 90vw !important;
}
.product-details{
  flex-direction: column;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
  padding-bottom: 15vh !important;
}
.product-details .col-5{
  width: 100%;
}
.product-details .col-7{
  width: 100%;
}
.productimage{
  width: 100vw !important;
}
.productimage img{
  width: 55vw !important;
}.productdetail{
  height: auto !important;
  width: 100vw !important;
}

.productdetail hr{
  width: 50%;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.listof-doctors{
  margin: 2px !important;
}
.doctor{
  margin: 0px !important;
  margin-bottom: 2vh !important;
  /* height: 80% !important; */
  padding: 1rem !important;
  margin-top: 2rem !important;
}
  .doctor-profile{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .doctor-profile img{
    width: 35vw !important;

  }
  .serch-by-prescription{
    width: auto !important;
  }
  .deccription img{
    max-width: 50vw !important;
  }
  .doctor_deccription{
    width: 80vw !important;
  }
  .allSpecialitiesDoctorsCarousel{
    height: 20vh !important;
  }
  .allDoctorsCarousel{
    height: 30vh !important;
  }
  .allLabsCarousel{
    height: 25vh !important;
  }
  .allLabTestCarousel{
    height: 27vh !important;
  }
  .allMedicinesShopsCarousel{
    height: 22vh !important;
  }
  .allCategoryCarousel{
    height: 25vh !important;
  }
  .productCarousel{
    height: 20vh !important;
  }
  
  .prescription_pad{
    width: 97% !important;
    font-size: 12px !important;
  }


@media screen and (max-width: 450px) {

  
  
  .allCategoryCarousel{
    height: 35vh !important;
    overflow: hidden !important;
  }

  .navbar-brand h5{
    font-size:calc(1vw + 0.5rem) !important;
    margin-top: 10px; 
  }
  .login-btn-text{
    padding: 6px !important;
  }
  /*.business-btn-text{
    /* padding: 6px 2px !important; 
  } */
  
  .search-location-under{
    width: 80vw !important;
   }
   .drop-dwon-profile {
    width: 7vw;
    height: 7vh;
  }
  /* .cart p {
    margin-bottom: 2rem !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  } */
  .product-card-card{
    max-width: 45vw !important;
  }
  .product-card{
    width: 45vw !important;
  }
  .medicine-shop{
    width: 38vw !important;
  }
  .medicine-card-card{
    width: 38vw !important;
  }
  .doctor{
    margin-top: 2rem !important;
  }
  .Appointment-confirm h1{
    font-size: calc(4vw + 0.5rem) !important;
  }
  .after-noon-time{
    margin: 0 0rem !important;
  }
  .Appointment-confirm .h-line{
    display: none !important;
  }
  .offers-header2{
    display: none !important;
  }
  .choose-role-div{
    width: 100vw !important;

  }
  .person{
    height: 20%;
    width: 15% !important;
  }
  .complete_profile_divs{
    flex-direction: column !important;
  }
  .complete_profile_divs input{
    width: 80vw !important;
  }
  .prescription_pad{
    width: 97% !important;
    .prescription_pad{
      width: 97% !important;
    }
  }
  
}


@media screen and (max-width: 350px) {
  #mob-chooserole-lable{
    padding: 0px !important ;
  }
  #sub_admin_login_mob{
    padding: 2px !important ;
    margin: 2px !important ;
  }
  #sub_admin_signup_mob{
    padding: 2px !important ;
    margin: 2px !important ;
  }
}


#phone {
   outline: 0;
}
  
/*#phone:valid{
  border-color: var(--green);
  border: 2px solid var(--green)!important;
}*/
  
 /* #phone:focus:invalid {
  border-color: var(--red);
  border: 2px solid var(--red) !important;

}*/

input:invalid + span:after {
  position: absolute;
  content: "\2716";
  /* padding-left: 1rem; 
  color: #d9534f;*/
  font-size: 1rem;
  line-height: 50px;
}

input:valid + span:after {
  position: absolute;
  content: "\2713";
  /* padding-left: 1rem; 
  color: #5cb85c;*/
  font-size: 1rem;
  line-height: 50px;
}

#phone {
  width: calc(100% - 2.5rem);
  padding: 1rem;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  /*color: var(--primary-color);
  background-color: var(--primary-bg-color);
  border: 2px solid var(--secondary-color);*/
  background-image: none;
  border-radius: 5px;
  outline: none;  
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: all .2s
}

#phone:focus {
  outline: 0;
}

/*#phone:valid {
  border-color: #3c763d78;
}

#phone:focus:invalid {
  border-color: rgb(217 83 79 / 60%);
}*/
/* 
.btn {
  width: 100%;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  padding: 1rem 0;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s
} */

/* .btn:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
} */

/* small {
  color: var(--gray-300);
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: block;
  font-size: 0.875rem;
} */

/* .content {
	background-color: white;	
  padding: 2.5rem;
  border-radius: 0.5rem;
  max-width: 32rem;
  margin: 2rem auto;
}
#label {
  margin-top: 0;
  margin-bottom: 2rem;
} */}

.container-bill {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.content-bill {
  flex-grow: 1;
  padding: 20px;
}

.content-header-bill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.sales-table-bill {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.sales-table-bill th, .sales-table-bill td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  color: black;
}

.sales-table-bill th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.add-button-bill {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-button-bill:hover {
  background-color: #0056b3;
}

/* Edit button styling */
.edit-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #218838;
}

/* Delete button styling */
.delete-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #c82333;
}

/* Modal overlay to cover the background */
.modal-overlay-bill {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content styling */
.modal-content-bill  {
  width: 650px; /* Set a specific width */
  max-width: 90%; /* Responsive max width */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: 0 auto;
}

/* Buttons styling */
.modal-buttons-bill  {
  margin-top: 20px;
}

/* Flex container for the buttons */
.button-container-bill {
  display: flex;
  justify-content: center; /* Center the buttons */
  margin-bottom: 20px; /* Space between the buttons and the bottom button */
}

/* Button styles */
.modal-button-bill {
  margin: 0 10px; /* Adjust space between buttons */
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Center the bottom button */
.bottom-button-bill {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Close button */
.close-button-bill {
  padding: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
/* 
  for gridElement.js 
                        */

/*body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: white;
}*/

.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
  grid-gap: 20px;
  padding: 20px;
}

.grid-item {
  position: relative;
  text-align: center;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
}

.trending-label {
  position: absolute;
  top: 7px;
  left: 10px;
  background-color: #007bff;
  color: white;
  padding: 5px;
  font-weight: bold;
  font-size: 10px;
  border-radius: 5px;
  z-index: 2;
}

.image-container {
  position: relative;
  display: inline-block;
}

.rating {
  position: relative;
  margin-top: 10px;
  font-size: 10px;
  /*color: #FFD700*/;
}

.star-icon {
  margin-left: 2px;
}

.rating-overlay {
  position: absolute;
  bottom: 7px;
  left: 10px;
  display: flex;
  align-items: center;
  /*background-color: rgba(0, 0, 0, 0.6);  Semi-transparent black background */
  color: green;
  padding: 3px 6px;
  border-radius: 5px;
}

.rating-text {
  margin-right: 5px;
}

.grid-item img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}


.scrollable-section {
  overflow-x: auto;
  padding: 20px 0;
}

.items-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 200px;
  margin-left: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.item-scroll {
  width: 200px;
  height: 250px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.item-scroll img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.item-scroll h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
}

.item-scroll p {
  font-size: 1rem;
  color: #666;
  margin: 10px 0;
}

/* For mobile view */
@media (max-width: 768px) {
  .items-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    width: max-content; /* To allow horizontal scrolling */
    grid-template-rows: repeat(2, 1fr);
    /*grid-auto-flow: row;*/
  }

  .item-scroll {
    width: 150px;
    height: 220px;
  }

  .item-scroll img {
    height: 120px;
  }
}



/* for top-offers section */

.carousel-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.offer-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px; /* For larger screens */
  margin: 0 auto; /* Centers card within the carousel */
}

.offer-card-container img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Prevent image distortion */
}

.offer-card-container .description {
  text-align: center;
  padding: 10px;
}

.offer-card-container .title {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.offer-card-container .discount {
  background-color: #ff6347; /* Example: Orange background for discount */
  color: white;
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .offer-card-container {
    max-width: 100%; /* On smaller screens, the card takes full width */
  }
}

@media (max-width: 480px) {
  .offer-card-container {
    max-width: 100%; /* Full width on mobile */
    max-height: 100%;
  }

  .offer-card-container img {
    max-height: 200px; /* Limit image height on small screens */
  }
}




/* new code for b2b home */
@media (max-width: 600px) {
  .offer-deals-image {
    width: 20vw;
    height: 20vw;
  }
  .b2b-card-image {
    max-height: 9vh;
    min-height: 9vh;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .offer-deals-image {
    width: 12vw;
    height: 12vw;
  }
  .b2b-card-image {
    max-height: 10vh;
    min-height: 10vh;
  }
}
@media (max-width: 400px){
  .related-product{
    margin-top: 0;
    background-color: aquamarine;
    padding: 0;
  }
}

@media (min-width: 1025px) {
  .offer-deals-image {
    width: 9vw;
    height: 9vw;
  }
  .b2b-card-image {
    max-height: 20vh;
    min-height: 20vh;
  }
}

.b2b-card-image:hover {
  cursor: pointer;
}
/* @media(max-width :550px){
  .mx-auto{
    margin-top: 0;
  }
} */
:root {
  --main-bg-color: #99d9d9;
  --white: #fff;
  --black: #000;
  --mediumseagreen: #3cb371;
  --green: #008000;
  --red: #ff0000;
  --light-cyan: #dcffff;
  --fade-black: rgba(0, 0, 0, 0.35);
  --fern-green: #5cb85c;
  --valencia: #d9534f;
  --light-grey: #cccccc;
  --gainsboro-grey: #dddddd;
  --white-smoke: #f2f2f2;
  --dodger-blue: #007bff;
  --robins-egg-blue: #07dbc1;
  --deep-sea-green: #187267;
  --allports-blue: #297484;
  --fade-red: rgba(217, 83, 79, 0.6);
  --killarney-green: rgba(60, 118, 61, 0.471);
  --light-grayish-cyan: rgba(202, 224, 224, 0.969);

  /* Font families */
  --font-primary: "Quicksand", sans-serif !important;;
  --font-secondary: 'Arial', sans-serif;

  /* Font sizes */
  --font-size-small: 0.875rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.25rem;
  --font-size-xlarge: 1.5rem;

  /* Font weights */
  --font-weight-normal: 400;
  --font-weight-bold: 700;

  /* Line heights */
  --line-height-normal: 1.5;
  --line-height-tight: 1.25;
}

   .main-medicine-page {
    background-color: #f5f5f5;
   }

   .service {
    background-color: #F5F5F5;
   }

   .App {
    background-color: var(--white);
   }

   .dashboard-main-container .card{
    background-color: var(--light-cyan);
  }

  h1{
    color:var(--black) !important;
  }
  h2{
    color:var(--black) !important;
  }
  h5{
    color:var(--black) !important;
  }
  h3{
    color:var(--black) !important;
  }
  h6{
    color:var(--black) !important;
  }

  .inputResult {
    background-color: var(--white);
    box-shadow: var(--fade-black) 0px 5px 15px;
  }

  .inputResultofmodal {
    background-color: var(--white);
    box-shadow: var(--fade-black) 0px 5px 15px;
  }

  .HamburgerMenu span{
    color: var(--white);
  }

  .HamburgerMenu  h5{
    color: var(--white);
  }

  .HamburgerMenu ul {
     background-color: var(--deep-sea-green);
     color: var(--white);
 }

 .product-card-div a{
    color: var(--black);
 }

 .person{
    border: 5px solid var(--robins-egg-blue);
 }

 .product-btn:hover{
    background-color: var(--light-grayish-cyan) !important;
  }

  .header-location-1{
    color: var(--black) !important;
    background-color: var(--white) !important;
  }

  .choose-role-div{
    background-color: var(--allports-blue);
  }

  .prescription_pad{
    border : 4px solid var(--fade-black); /* #00000042*/
  }

  .prescription_head{
    border-bottom : 4px solid var(--fade-black); /*#00000042*/
  }

  .prescription_left{
    border-right : 4px solid var(--fade-black); /*#00000042*/
  }

  .desease_details .dd_header{
    background: var(--mediumseagreen);
    color: var(--white);
  }

  .tab{
    border: 1px solid var(--light-grey);
  }

  .tab:hover {
    background-color: var(--white-smoke); /*#f0f0f0;*/
  }

  .tab.active {
    background-color: var(--dodger-blue); /* Change this color for the active tab */
    color: var(--white); /* Text color for active tab */
  }

  .tab-content{
    border: 1px solid var(--light-grey);
  }

  .tablink{
    background-color: var(--white-smoke);
  }

  .tablink.active {
    background-color: var(--gainsboro-grey);
  }

  .header-location-mobile{
    color: var(--black) !important;
    background-color: var(--white) !important;
  }

  .Header2-search img{
    border: 2px solid var(--white);
  }

  #phone:valid{
    border-color: var(--green);
    border: 2px solid var(--green)!important;
  }

  #phone:focus:invalid {
    border-color: var(--red);
    border: 2px solid var(--red) !important;
  
  }

  input:invalid + span:after{
    color: var(--valencia);
  }

  input:valid + span:after{
    color: var(--fern-green);
  }

  #phone {
    color: var(--primary-color);
    background-color: var(--primary-bg-color);
    border: 2px solid var(--secondary-color);
  }

  #phone:valid {
    border-color: var(--killarney-green);
  }
  
  #phone:focus:invalid {
    border-color: var(--fade-red);
  }
.autocomplete-dropdown-container {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 999;
    width: 56%;
    max-height: 200px;
    overflow-y: auto;
    margin-left: 16px;
    
  }
  
  .suggestion-item {
    padding: 5px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #fafafa;
  }
  
  .suggestion-item--active {
    background-color: #e5e5e5;
  }

  
.inputStyle {
    box-shadow: inset 0 0 10px #eee !important;
    border: 2px solid #eee;
    width: 100%;
    height: 40px;
    margin-left: 16px;
    border-radius: 5px;
    font-weight: 300 !important;
    outline: none;
    padding: 10px 20px;
    /* margin-bottom: 10px; */
  }
.btnonhover {
    transition: all 0.2s;
}

.btnonhover:hover {
    background-color: #6776ed;
    color: #fff;
}

.navbar {
    /* background-color: #212121; */
    display: flex;
}

.left {
    justify-content: space-between;
}

.login-order {
    display: flex;
}

.h2-t {
    font-size: calc(1vw + 0.5rem) !important;
}

.carousel-container {
    padding-bottom: 20px;
    overflow: hidden !important;
}

.carousel-item-padding-40-px {
    padding: 0 20px;
}

.custom-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    color: #333;
    border-radius: 5px;
    cursor: pointer;
    flex: 1 1;
    text-align: center;
    margin: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-button:hover {
    background-color: #d0d0d0;
}

.custom-button.active {
    background-color: #0d6efd;
    color: white;
    /* font-weight: bold; */
}

@media (max-width: 768px) {
    .carousel-inner {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        display: flex;
    }

    .carousel-item {
        flex: 0 0 auto;
        scroll-snap-align: center;
        width: 80%;
        /* Adjust the width as needed */
        margin-right: 10px;
    }

    .carousel-inner img {
        width: 100%;
        height: auto;
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: none;
        /* Hide navigation buttons on smaller screens */
    }
}

.headerWidth {
    width: 90vw;
}

.hea2 {
    height: 6rem;
    grid-gap: 5rem;
    gap: 5rem;
}

.ch {
    height: 4rem;
    width: 4rem;
}

.lg {
    height: 3rem;
    width: 3rem;
}

.custom-font {
    font-size: 0.75rem;
    /* fs-7 equivalent */
}


.cr {
    height: 17rem;
    width: 13rem;
}

.tw {
    width: 12rem;
}

.crim {
    height: 10rem;
    width: 10rem;
}

.carw {
    width: 90vw;
}

.fn {
    font-size: 1.15rem;
}


.cartim {
    width: 100px;
}

.cartw {
    width: 7rem;
}

.bannerh {
    height: 35vh;
}

.bannerw {
    width: 90vw;
}

.invert {
    filter: invert(1);
}

.bannerst {
    top: 20px;
    right: 25%;
}

.bh {
    font-size: 30px;
}

.blh {
    height: 3vh;
    width: 3vw;
}

.carth {
    height: 2rem;
    width: 2rem;
}

.cartvalu {
    height: 1.3rem;
    width: 1.3rem;
    padding: 5px;
    right: 0rem;
}

.scarch {
    width: 45vw;
    height: 4vh
}

.sh {
    font-size: 2rem;
}

.addressbar{
    width: 40vw;
}

.hovimage:hover .crim{
    transition: transform .2s ease; 
    transform: scale(1.07);
}

@media (max-width: 768px) {
    .addressbar{
        width: 90vw;
    }
    .sh {
        font-size: 10rem;
    }

    .scarch {
        height: 1.75rem;
    }

    .cartvalu {
        height: 0.75rem;
        width: 0.75rem;
        padding: 2px;
        right: 0.3rem;
        font-size: "5px";
    }

    .scarch {
        width: 40rem;
    }

    .carth {
        height: 1.5rem;
        width: 1.5rem;
    }

    .bannerst {
        top: 10px;
        right: 22%;
    }

    .blh {
        height: 5vh;
        width: 5vw;
    }

    .invert {
        filter: invert(0);
    }

    .bannerw {
        width: 100vw;
    }

    .bannerh {
        height: 27vh;
    }

    .cartw {
        width: 3rem;
    }

    .cartim {
        width: 70px;
    }

    .card {
        width: 80%;
        /* adjust width */
        margin: 10px auto;
        /* center cards */
    }

    .card-img-top {
        height: 100px;
        /* adjust image height */
    }

    .card-body {
        padding: 10px;
        /* adjust padding */
    }

    .headerWidth {
        width: 100vw;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .hea2 {
        height: 4rem;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .ch {
        height: 2rem;
        width: 2rem;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .lg {
        height: 2.5rem;
        width: 2.5rem;
    }

    .custom-font {
        font-size: 2rem;
        /* fs-6 equivalent */
    }

    .cr {
        height: 12rem;
        width: 10rem;
    }

    .tw {
        width: 9rem;
    }

    .crim {
        height: 6rem;
        width: 6rem;
    }

    .carw {
        width: 100vw;
    }

    .fn {
        font-size: 0.75rem;
    }
}

@media (max-width: 800px) {

    /* .cr{
        height: 15rem;
        width: 10rem;
    } */
    .tw {
        width: 7rem;
    }
}
.addnewaddressdiv {
    display: flex; 
    align-items: center; 
    justify-content: space-between;
}
.addnewaddressdivfinal {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 5px;
}
.allbannerbutton{
    width: 5vw; 
    color: black; 
}
#MdKeyboardArrowRightid {
    height: 4vh; 
    width: 4vw;
}
#addnewaddresspara {
    margin-bottom: inherit; 
    margin-left: '5%'
}
.no-border {
    border: none;
  }

.sidebar .accordion-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.sidebar .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.sidebar .accordion-body {
    padding: 0 0 0 1rem;
}

.hoverbutton{
    color: black;
}
.hoverbutton:hover  {
    color: black;
    background-color: #6d6d6d;
}
.accordion-header .accordion-icon {
    font-size: 0.5rem; /* adjust the font size to your liking */
  }
.slider-wrapper {
    display: flex;
    justify-content: center; /* Centers the slider horizontally */
    align-items: center; /* Vertically centers content in the middle of the page */
    /*height: 100vh;  Full viewport height */
  }
  
  .slider-container {
    width: 600px;
    height: 400px;
    overflow: hidden;
    position: relative;
  }
  
  .slider {
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  .slider-image {
    min-width: 100%;
    height: 100%;
    margin-right: 15px; /* Adds gap between slides */
    border-radius: 10px; /* Optional: Gives rounded corners to the images */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for a better look */
  }
  
 
  
  
