.addnewaddressdiv {
    display: flex; 
    align-items: center; 
    justify-content: space-between;
}
.addnewaddressdivfinal {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 5px;
}
.allbannerbutton{
    width: 5vw; 
    color: black; 
}
#MdKeyboardArrowRightid {
    height: 4vh; 
    width: 4vw;
}
#addnewaddresspara {
    margin-bottom: inherit; 
    margin-left: '5%'
}
.no-border {
    border: none;
  }

.sidebar .accordion-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.sidebar .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.sidebar .accordion-body {
    padding: 0 0 0 1rem;
}

.hoverbutton{
    color: black;
}
.hoverbutton:hover  {
    color: black;
    background-color: #6d6d6d;
}
.accordion-header .accordion-icon {
    font-size: 0.5rem; /* adjust the font size to your liking */
  }