.slider-wrapper {
    display: flex;
    justify-content: center; /* Centers the slider horizontally */
    align-items: center; /* Vertically centers content in the middle of the page */
    /*height: 100vh;  Full viewport height */
  }
  
  .slider-container {
    width: 600px;
    height: 400px;
    overflow: hidden;
    position: relative;
  }
  
  .slider {
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  .slider-image {
    min-width: 100%;
    height: 100%;
    margin-right: 15px; /* Adds gap between slides */
    border-radius: 10px; /* Optional: Gives rounded corners to the images */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for a better look */
  }
  
 
  
  