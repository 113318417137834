.btnonhover {
    transition: all 0.2s;
}

.btnonhover:hover {
    background-color: #6776ed;
    color: #fff;
}

.navbar {
    /* background-color: #212121; */
    display: flex;
}

.left {
    justify-content: space-between;
}

.login-order {
    display: flex;
}

.h2-t {
    font-size: calc(1vw + 0.5rem) !important;
}

.carousel-container {
    padding-bottom: 20px;
    overflow: hidden !important;
}

.carousel-item-padding-40-px {
    padding: 0 20px;
}

.custom-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    color: #333;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
    text-align: center;
    margin: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-button:hover {
    background-color: #d0d0d0;
}

.custom-button.active {
    background-color: #0d6efd;
    color: white;
    /* font-weight: bold; */
}

@media (max-width: 768px) {
    .carousel-inner {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        display: flex;
    }

    .carousel-item {
        flex: 0 0 auto;
        scroll-snap-align: center;
        width: 80%;
        /* Adjust the width as needed */
        margin-right: 10px;
    }

    .carousel-inner img {
        width: 100%;
        height: auto;
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: none;
        /* Hide navigation buttons on smaller screens */
    }
}

.headerWidth {
    width: 90vw;
}

.hea2 {
    height: 6rem;
    gap: 5rem;
}

.ch {
    height: 4rem;
    width: 4rem;
}

.lg {
    height: 3rem;
    width: 3rem;
}

.custom-font {
    font-size: 0.75rem;
    /* fs-7 equivalent */
}


.cr {
    height: 17rem;
    width: 13rem;
}

.tw {
    width: 12rem;
}

.crim {
    height: 10rem;
    width: 10rem;
}

.carw {
    width: 90vw;
}

.fn {
    font-size: 1.15rem;
}


.cartim {
    width: 100px;
}

.cartw {
    width: 7rem;
}

.bannerh {
    height: 35vh;
}

.bannerw {
    width: 90vw;
}

.invert {
    filter: invert(1);
}

.bannerst {
    top: 20px;
    right: 25%;
}

.bh {
    font-size: 30px;
}

.blh {
    height: 3vh;
    width: 3vw;
}

.carth {
    height: 2rem;
    width: 2rem;
}

.cartvalu {
    height: 1.3rem;
    width: 1.3rem;
    padding: 5px;
    right: 0rem;
}

.scarch {
    width: 45vw;
    height: 4vh
}

.sh {
    font-size: 2rem;
}

.addressbar{
    width: 40vw;
}

.hovimage:hover .crim{
    transition: transform .2s ease; 
    transform: scale(1.07);
}

@media (max-width: 768px) {
    .addressbar{
        width: 90vw;
    }
    .sh {
        font-size: 10rem;
    }

    .scarch {
        height: 1.75rem;
    }

    .cartvalu {
        height: 0.75rem;
        width: 0.75rem;
        padding: 2px;
        right: 0.3rem;
        font-size: "5px";
    }

    .scarch {
        width: 40rem;
    }

    .carth {
        height: 1.5rem;
        width: 1.5rem;
    }

    .bannerst {
        top: 10px;
        right: 22%;
    }

    .blh {
        height: 5vh;
        width: 5vw;
    }

    .invert {
        filter: invert(0);
    }

    .bannerw {
        width: 100vw;
    }

    .bannerh {
        height: 27vh;
    }

    .cartw {
        width: 3rem;
    }

    .cartim {
        width: 70px;
    }

    .card {
        width: 80%;
        /* adjust width */
        margin: 10px auto;
        /* center cards */
    }

    .card-img-top {
        height: 100px;
        /* adjust image height */
    }

    .card-body {
        padding: 10px;
        /* adjust padding */
    }

    .headerWidth {
        width: 100vw;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .hea2 {
        height: 4rem;
        gap: 2rem;
    }

    .ch {
        height: 2rem;
        width: 2rem;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .lg {
        height: 2.5rem;
        width: 2.5rem;
    }

    .custom-font {
        font-size: 2rem;
        /* fs-6 equivalent */
    }

    .cr {
        height: 12rem;
        width: 10rem;
    }

    .tw {
        width: 9rem;
    }

    .crim {
        height: 6rem;
        width: 6rem;
    }

    .carw {
        width: 100vw;
    }

    .fn {
        font-size: 0.75rem;
    }
}

@media (max-width: 800px) {

    /* .cr{
        height: 15rem;
        width: 10rem;
    } */
    .tw {
        width: 7rem;
    }
}