:root {
  --main-bg-color: #99d9d9;
  --white: #fff;
  --black: #000;
  --mediumseagreen: #3cb371;
  --green: #008000;
  --red: #ff0000;
  --light-cyan: #dcffff;
  --fade-black: rgba(0, 0, 0, 0.35);
  --fern-green: #5cb85c;
  --valencia: #d9534f;
  --light-grey: #cccccc;
  --gainsboro-grey: #dddddd;
  --white-smoke: #f2f2f2;
  --dodger-blue: #007bff;
  --robins-egg-blue: #07dbc1;
  --deep-sea-green: #187267;
  --allports-blue: #297484;
  --fade-red: rgba(217, 83, 79, 0.6);
  --killarney-green: rgba(60, 118, 61, 0.471);
  --light-grayish-cyan: rgba(202, 224, 224, 0.969);

  /* Font families */
  --font-primary: "Quicksand", sans-serif !important;;
  --font-secondary: 'Arial', sans-serif;

  /* Font sizes */
  --font-size-small: 0.875rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.25rem;
  --font-size-xlarge: 1.5rem;

  /* Font weights */
  --font-weight-normal: 400;
  --font-weight-bold: 700;

  /* Line heights */
  --line-height-normal: 1.5;
  --line-height-tight: 1.25;
}

   .main-medicine-page {
    background-color: #f5f5f5;
   }

   .service {
    background-color: #F5F5F5;
   }

   .App {
    background-color: var(--white);
   }

   .dashboard-main-container .card{
    background-color: var(--light-cyan);
  }

  h1{
    color:var(--black) !important;
  }
  h2{
    color:var(--black) !important;
  }
  h5{
    color:var(--black) !important;
  }
  h3{
    color:var(--black) !important;
  }
  h6{
    color:var(--black) !important;
  }

  .inputResult {
    background-color: var(--white);
    box-shadow: var(--fade-black) 0px 5px 15px;
  }

  .inputResultofmodal {
    background-color: var(--white);
    box-shadow: var(--fade-black) 0px 5px 15px;
  }

  .HamburgerMenu span{
    color: var(--white);
  }

  .HamburgerMenu  h5{
    color: var(--white);
  }

  .HamburgerMenu ul {
     background-color: var(--deep-sea-green);
     color: var(--white);
 }

 .product-card-div a{
    color: var(--black);
 }

 .person{
    border: 5px solid var(--robins-egg-blue);
 }

 .product-btn:hover{
    background-color: var(--light-grayish-cyan) !important;
  }

  .header-location-1{
    color: var(--black) !important;
    background-color: var(--white) !important;
  }

  .choose-role-div{
    background-color: var(--allports-blue);
  }

  .prescription_pad{
    border : 4px solid var(--fade-black); /* #00000042*/
  }

  .prescription_head{
    border-bottom : 4px solid var(--fade-black); /*#00000042*/
  }

  .prescription_left{
    border-right : 4px solid var(--fade-black); /*#00000042*/
  }

  .desease_details .dd_header{
    background: var(--mediumseagreen);
    color: var(--white);
  }

  .tab{
    border: 1px solid var(--light-grey);
  }

  .tab:hover {
    background-color: var(--white-smoke); /*#f0f0f0;*/
  }

  .tab.active {
    background-color: var(--dodger-blue); /* Change this color for the active tab */
    color: var(--white); /* Text color for active tab */
  }

  .tab-content{
    border: 1px solid var(--light-grey);
  }

  .tablink{
    background-color: var(--white-smoke);
  }

  .tablink.active {
    background-color: var(--gainsboro-grey);
  }

  .header-location-mobile{
    color: var(--black) !important;
    background-color: var(--white) !important;
  }

  .Header2-search img{
    border: 2px solid var(--white);
  }

  #phone:valid{
    border-color: var(--green);
    border: 2px solid var(--green)!important;
  }

  #phone:focus:invalid {
    border-color: var(--red);
    border: 2px solid var(--red) !important;
  
  }

  input:invalid + span:after{
    color: var(--valencia);
  }

  input:valid + span:after{
    color: var(--fern-green);
  }

  #phone {
    color: var(--primary-color);
    background-color: var(--primary-bg-color);
    border: 2px solid var(--secondary-color);
  }

  #phone:valid {
    border-color: var(--killarney-green);
  }
  
  #phone:focus:invalid {
    border-color: var(--fade-red);
  }