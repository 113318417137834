.autocomplete-dropdown-container {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 999;
    width: 56%;
    max-height: 200px;
    overflow-y: auto;
    margin-left: 16px;
    
  }
  
  .suggestion-item {
    padding: 5px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #fafafa;
  }
  
  .suggestion-item--active {
    background-color: #e5e5e5;
  }

  
.inputStyle {
    box-shadow: inset 0 0 10px #eee !important;
    border: 2px solid #eee;
    width: 100%;
    height: 40px;
    margin-left: 16px;
    border-radius: 5px;
    font-weight: 300 !important;
    outline: none;
    padding: 10px 20px;
    /* margin-bottom: 10px; */
  }